<template>
    <div class="lg:flex lg:items-stretch shadow rounded-lg border border-gray-400 my-2 bg-gray-100">
        <div v-bind:class="{ 'bg-primary': isFuture, 'bg-gray-500': !isFuture }" class="rounded-lg lg:w-2/12 py-2 sm:py-4 block shadow-inner">
            <div class="text-center tracking-wide min-w-24">
                <div class="text-white font-bold text-4xl ">
                    {{ startts | dayOfMonth}}
                </div>
                <div 
                    class="text-white font-normal" v-bind:class="{ 'text-2xl': !showYear, 'text-xl': showYear }">
                    {{ startts | monthName | toLowerCase }}
                    <span v-if="showYear">{{ startts | year }}</span>
                </div>
            </div>
        </div>
        <div class="w-full lg:w-5/12 px-1 bg-gray-100 py-5 lg:px-2 lg:py-2 tracking-wide ">
            <div class="flex flex-row lg:justify-start justify-center pl-2 ">
                <div class="text-gray-700 font-medium text-sm text-center lg:text-left min-w-8">
                    <font-awesome-icon icon="clock" class="inline-block"/> 
                </div>
                <div class="text-gray-700 font-medium text-sm text-center lg:text-left">
                    <span class="align-bottom">{{ startts | time}}</span>
                </div>
            </div>
            <div class="flex flex-row font-semibold text-gray-800 text-xl text-center lg:text-left pl-2">
                <div v-if="nbroftickets > 0" class="min-w-8">
                    <span class="text-gray-400">
                        {{ nbroftickets }}x
                    </span>                
                </div>
                <div>
                    {{ name }}
                    <span class="inline-block border border-blue rounded py-1 px-3 text-white bg-gray-800 text-sm" v-if="status">
                        {{ status }}
                    </span>
                </div>
            </div>
            <div class="text-gray-600 font-medium text-base text-center lg:text-left pl-2">
                {{ subtitle }}
            </div>
        </div>

        <div class="flex flex-row items-center w-full lg:w-5/12 bg-gray-100 lg:justify-end justify-center px-2 py-2 sm:py-4 lg:px-0">  
            <slot></slot>
        </div>
    </div>
</template>

<script>
import i18n from '@/plugins/i18n'
const moment = require("moment")
moment.locale(i18n.locale)

export default {
  props: {
      id: Number,
      name: String,
      subtitle: String,
      startts: String,
      endts: String,
      status: String,
      nbroftickets: Number,
  },
  computed: {
    isFuture() {
        return moment(this.startts) > moment();
    },
    isNextYear() {
        return moment(this.startts).year() > moment().year();
    },
    isCurrentYear() {
        return moment(this.startts).year() === moment().year();
    },
    showYear() {
        return !this.isFuture || !this.isCurrentYear;
    },
    nbrOfTicketsText() {
      if (this.nbroftickets === 0) {
          return ""
      }
      else if (this.nbroftickets === 1) {
          return `${this.nbroftickets} ticket`
      }
      return `${this.nbroftickets} tickets`
    },
  },
  filters: {
    dayOfMonth: function (date) {
        return moment(date).format('D')
    },
    dayOfWeek: function (date) {
        return moment(date).format('ddd').slice(0, -1)
    },
    monthName: function (date) {
        const str = moment(date).format('MMM').slice(0, -1)
        if (str === 'me') {
            return 'mei';
        }
        return str;
    },
    year: function (date) {
        return moment(date).format('YYYY')
    },
    toLowerCase: function (s) {
        return s.toLowerCase()
    },
    time: function (date) {
        return moment(date).format('HH:mm')
    },
  },
}
</script>